<template>
  <div class="footer-wrapper">
    <transition name="van-fade">
      <div class="tips" v-show="showTips && havaRepetition">
        <span class="bold">已去除筛查项目中的重复项</span>
        <img
          class="icon-close"
          src="@/assets/icon-tips-close.png"
          alt=""
          @click="showTips = false"
        />
      </div>
    </transition>
    <div class="footer">
      <div class="footer-left">
        <van-icon
          name="shopping-cart"
          color="#2C2F37"
          :badge="badge"
          size="30px"
          @click="showPopup = true"
        />
        <div class="footer-price">
          <span class="bold">合计</span>
          <span class="total-price"
            ><span class="bold" style="font-size: 14px">¥</span
            >{{ totalPrice }}</span
          >
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-button-default" @click="jumpAddItem">更多加项</div>
        <div class="footer-button-primary" @click="confirm">选好了</div>
      </div>
    </div>

    <van-popup v-model="showPopup" closeable position="bottom" round>
      <div class="popup-header">已选项目</div>
      <div class="popup-content">
        <div class="popup-item-header">
          必查体检项目<span class="base-price">￥{{ basePrice }}</span>
        </div>
        <transition-group name="van-fade" tag="div" style="position: relative">
          <div
            v-show="showMore || index < 7"
            class="popup-item"
            v-for="(item, index) in projects"
            :key="item"
          >
            <div class="popup-item-content">
              <span>{{ item }}></span>
              <span>￥50</span>
            </div>
            <img class="icon-radio" :src="iconDisabled" alt="" />
          </div>
        </transition-group>

        <div v-if="!showMore" class="look-more">
          <span @click="showMore = true">查看更多</span>
        </div>

        <template v-if="selectedProjects?.length">
          <div class="popup-item-header">自选筛查项目</div>
          <div>
            <div
              class="popup-item"
              v-for="packages in selectedProjects"
              :key="packages.projectName"
              @click="onClickPackages(packages)"
            >
              <div class="popup-item-content">
                <div style="display: flex; flex-direction: column">
                  <span>{{ packages.projectName }}></span>
                  <span v-if="packages.attributionAdditionPackageName" class="popup-item-content-desc"
                    >{{ packages.attributionAdditionPackageName }}</span
                  >
                </div>
                <span>￥{{ packages.projectPrice }}</span>
              </div>
              <img class="icon-radio" :src="packages.checked ? iconChecked:iconDefault" alt="" />
            </div>
          </div>
        </template>
      </div>
    </van-popup>
  </div>
</template>

<script>
import iconDisabled from "@/assets/icon-project-disabled.png";

import iconDefault from "@/assets/icon-project-default.png";
import iconChecked from "@/assets/icon-project-checked.png";
export default {
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    selectedProjects: {
      type: Array,
      default: () => [],
    },
    havaRepetition: {
      type: Boolean,
    },
    totalPrice: {
      type: Number | String,
    },
    basePrice: {
      type: Number,
    },
  },
  data() {
    return {
      showTips: true,
      showPopup: false,
      iconDefault,
      iconChecked,
      iconDisabled,
      showMore: false
    };
  },
  computed:{
    badge(){
      const len = this.$props.selectedProjects.filter(item => item.checked).length
      return len + this.$props.projects.length
    },
  },
  methods: {
    confirm() {
      this.$emit("onConfirm");
    },
    jumpAddItem() {
      this.$emit("jumpAddItem");
    },
    onClickPackages(project){
      this.$emit('onClickPackages',project)
    },
  },
};
</script>

<style scoped>
.footer-wrapper {
  position: relative;
  height: calc(constant(safe-area-inset-bottom) + 60px);
  height: calc(env(safe-area-inset-bottom) + 60px);
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: calc(constant(safe-area-inset-bottom) + 50px);
  height: calc(env(safe-area-inset-bottom) + 50px);
  background-color: #fff;
}

.footer-price {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  color: #2c2f37;
  font-size: 12px;
}

.footer-left {
  display: flex;
  align-items: center;
}

.total-price {
  color: #ff3d00;
  font-size: 20px;
  font-weight: bold;
}

.footer-right {
  display: flex;
  align-items: center;
}

.footer-button-primary,
.footer-button-default {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
}

.footer-button-primary {
  margin-left: 10px;
  background: #00c4b3;
  color: #fff;
}

.footer-button-default {
  background: #fff;
  color: #878d99;
  border: 1px solid #edeff0;
}

.tips {
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: calc(constant(safe-area-inset-bottom) + 60px);
  bottom: calc(env(safe-area-inset-bottom) + 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  background: #ffb470;
  border-radius: 10px;
  font-size: 14px;
  color: #ffffff;
}

.tips::after {
  content: "";
  position: absolute;
  left: 18px;
  bottom: -22px;
  border: solid;
  border-width: 12px 8px;
  border-color: #ffb470 transparent transparent transparent;
}

.icon-close {
  width: 12px;
  height: 12px;
}

.popup-header {
  margin: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
}

.popup-content {
  padding: 0 20px 60px 20px;
  max-height: 70vh;
  overflow-y: scroll;
}

.popup-item-header {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
}

.popup-item-header:first-child {
  margin-top: 0;
}

.base-price {
  font-size: 16px;
  font-weight: bold;
  color: #ff3d00;
}

.popup-item {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  color: #2c2f37;
}

.popup-item:first-child {
  margin-top: 10px;
}

.popup-item-content {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.icon-radio {
  margin-left: 40px;
  width: 20px;
  height: 20px;
}

.popup-item-content-desc {
  margin-top: 5px;
  font-size: 14px;
  color: #878d99;
}

.look-more {
  margin-top: 14px;
  width: 100%;
  font-size: 14px;
  color: #00c4b3;
  text-align: center;
}
</style>
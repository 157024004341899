<template>
  <div class="container">
    <div class="header">
      <div class="header-border"></div>
      <div>
        <div class="title">存在高危疾病风险</div>
        <div class="desc">建议定期筛查以下项目</div>
      </div>
    </div>

    <div>
      <div
        class="item"
        v-for="(item, index) in packages"
        :key="item.additionPackageId"
      >
        <div class="item-title">
          {{ item.additionPackageName }}
        </div>
        <div class="project" v-if="item?.hospitalProjects?.length">
          <div
            class="project-name"
            v-for="(project, projectIndex) in item.hospitalProjects"
            :key="project.projectName"
            @click="onClickProject(index,projectIndex)"
          >
            <span>{{ project.projectName }}></span>

            <img
              class="icon-radio"
              :src="project.checked ? iconChecked : iconDefault"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import iconDefault from "@/assets/icon-project-default.png";
import iconChecked from "@/assets/icon-project-checked.png";
export default {
  props: {
    packages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconDefault,
      iconChecked,
      
    };
  },
  methods: {
    onClickProject(index,projectIndex){

      this.$emit("onClickProject", index,projectIndex);

    },
  },
};
</script>
  
  <style scoped>
.container {
  background-color: #fff;
  border-radius: 10px;
}
.header {
  position: relative;
  padding: 15px;
  background: url("../../../assets/questionnaire-result-img.png") 100% 100%
    no-repeat;
  background-size: 100% 100%;
}
.header-border {
  position: absolute;
  left: -4px;
  top: 19px;
  width: 8px;
  height: 14px;
  background: #ff3d00;
  border-radius: 10px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #ff3d00;
}

.desc {
  margin-top: 4px;
  font-size: 14px;
  color: #878d99;
}

.item {
  margin: 10px 15px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #edeff0;
}

.item:first-child {
  margin-top: 0;
}

.item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
}

.item-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #ffb910;
}

.project {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  color: #2c2f37;
}

.project-name {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
  font-size: 14px;
  color: #2c2f37;
}

.icon-radio {
  width: 20px;
  height: 20px;
}
</style>
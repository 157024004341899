<template>
  <div>
    <img class="bg-img" src="@/assets/questionnaire-result-bg.png" alt="" />
    <div class="main">
      <baseProject
        :projects="result.projects"
        :basePrice="result.salePrice"
      ></baseProject>
      <recommendProject
        v-if="result?.hospitalPackages?.length"
        style="margin-top: 10px"
        :packages="result.hospitalPackages"
        @onClickProject="onClickProject"
      ></recommendProject>
    </div>
    <bottom
      :projects="result.projects"
      :selectedProjects="selectedProjects"
      :basePrice="result.salePrice"
      :totalPrice="totalPrice"
      :havaRepetition="havaRepetition"
      @onConfirm="onConfirm"
      @jumpAddItem="jumpAddItem"
      @onClickPackages="onClickPackages"
    ></bottom>
  </div>
</template>

<script>
import baseProject from "@/views/components/questionnaire-result/base-project.vue";
import recommendProject from "@/views/components/questionnaire-result/recommend-project.vue";
import bottom from "@/views/components/questionnaire-result/bottom.vue";
import { uniqueFunc } from "@/utils/index.js";
export default {
  components: {
    baseProject,
    recommendProject,
    bottom,
  },
  data() {
    return {
      result: {},
      havaRepetition: false, //是否有重复
    };
  },
  computed: {
    selectedProjects() {
      let projects = [];
      this?.result?.hospitalPackages?.forEach((item) => {
        item?.hospitalProjects?.forEach((project) => {
          projects.push(project);
        });
      });
      const projectId = projects.map((item) => item.projectId);
      if (new Set(projectId).size !== projectId.length) {
        //有重复项
        this.havaRepetition = true;
        projects = uniqueFunc(projects, "projectId");
      } else {
        this.havaRepetition = false;
      }
      return projects;
    },
    totalPrice() {
      const list = this.selectedProjects
        .filter((item) => item.checked)
        .map((item) => item.projectPrice);
      const price = list.reduce((pre, cur) => pre + cur, this.result.salePrice);
      return price && price.toFixed(2);
    },
  },
  mounted() {
    this.$setTitle("健康评估");
    document.querySelector("body").style.backgroundColor = "#F4F4F4";
    this.initData();
  },
  methods: {
    initData() {
      if (localStorage.getItem("questionnaireResult")) {
        const projects =
          localStorage.getItem("projects") &&
          JSON.parse(localStorage.getItem("projects"));
        let ids = [];
        if (projects?.length) {
          ids = projects.map((item) => item.projectId);
        }
        const list = JSON.parse(localStorage.getItem("questionnaireResult"));
        list.hospitalPackages.forEach((item) => {
          item.hospitalProjects.forEach((project) => {
            if (ids.includes(project.projectId)) {
              project.checked = true;
            } else {
              project.checked = false;
            }
          });
        });
        this.result = list;
      }
    },
    //加项包点击
    onClickProject(index, projectIndex) {
      const item =
        this.result.hospitalPackages[index].hospitalProjects[projectIndex];
      item.checked = !item.checked;
      console.log(item);
      this.result.hospitalPackages[index].hospitalProjects.splice(
        projectIndex,
        1,
        item
      );
      localStorage.setItem("projects", JSON.stringify(this.selectedProjects));

    },
    onClickPackages(project) {
      this.result.hospitalPackages.forEach((item, index) => {
        item.hospitalProjects.forEach((child, childIndex) => {
          if (child.projectId === project.projectId) {
            child.checked = !child.checked;
          }
        });
      });
      localStorage.setItem("projects", JSON.stringify(this.selectedProjects));
    },
    onConfirm() {
      const project = this.selectedProjects.filter((item) => item.checked);
      if (project?.length) {
        localStorage.setItem("projects", JSON.stringify(project));
      } else {
        localStorage.removeItem("projects");
      }
      this.$router.push("/confirm-order/" + this.result.hospitalProductId);
    },
    jumpAddItem() {
      const project = this.selectedProjects.filter((item) => item.checked);
      if (project?.length) {
        localStorage.setItem("projects", JSON.stringify(project));
      } else {
        localStorage.removeItem("projects");
      }
      this.$router.push("/add-item/" + this.result.hospitalProductId);
    },
  },
};
</script>

<style>
html body {
  background-color: #f4f4f4 !important;
}
</style>

<style scoped>
.bg-img {
  width: 100%;
}
.main {
  position: relative;
  margin-top: -100px;
  padding: 0 10px;
}
</style>
<template>
  <div class="container">
    <div class="header van-hairline--bottom">
      <div class="header-border"></div>
      <div>
        <div class="title">
          体检必查项目 <span class="base-price">￥{{ basePrice }}</span>
        </div>
        <div class="desc">基础检查项目是其他项目的重要参考</div>
      </div>
    </div>

    <transition-group name="van-fade" tag="div" style="position: relative">
      <div
        v-show="showMore || index < 7"
        class="item"
        v-for="(item, index) in projects"
        :key="item"
      >
        <span>{{ item }} ></span>
        <img
          class="icon-radio"
          src="@/assets/icon-project-disabled.png"
          alt=""
        />
      </div>
    </transition-group>

    <div v-if="!showMore" class="look-more">
      <span @click="showMore = true">查看更多</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    basePrice: {
      type: Number,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
};
</script>

<style scoped>
.container {
  background-color: #fff;
  border-radius: 10px;
}
.header {
  position: relative;
  padding: 15px;
}
.header-border {
  position: absolute;
  left: -4px;
  top: 19px;
  width: 8px;
  height: 14px;
  background: rgba(0, 196, 179, 0.5);
  border-radius: 10px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #2c2f37;
}

.base-price {
  font-size: 16px;
  font-weight: bold;
  color: #ff3d00;
}

.desc {
  margin-top: 4px;
  font-size: 14px;
  color: #878d99;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  width: 100%;
  font-size: 14px;
  color: #2c2f37;
}

.icon-radio {
  margin-left: 20px;
  width: 20px;
  height: 20px;
}

.look-more {
  width: 100%;
  height: 35px;
  font-size: 14px;
  color: #00c4b3;
  text-align: center;
}
</style>